<template>
    <GuestLayout data-comp="LoginGoogle">

        <div class="border-b-[1px] border-gray-300 mt-8 flex grid-cols-2 gap-8">
            <button
                @click="setActiveComponent('PlatformLoginCard', PlatformLoginCard)"
                class="border-b-3 border-transparent -mb-[2px] max-w-max font-bold pb-2
                text-gray-700 transition-all duration-300 ease-in-out"
                :class="activeComponent.title === 'PlatformLoginCard' ? '!border-heroes' : 'hover:text-primary-600'"
            >
                <span>Platform Access</span>
            </button>

            <button
                @click="setActiveComponent('OneTimePasswordLoginCard', OneTimePasswordLoginCard)"
                class="border-b-3 border-transparent -mb-[2px] max-w-max  pr-2 font-bold pb-2 text-gray-400
                transition-all duration-300 ease-in-out"
                :class="activeComponent.title === 'OneTimePasswordLoginCard' ? '!border-heroes' : 'hover:text-primary-600'"
            >
                <span>Administrative only </span>
            </button>
        </div>

        <Transition mode="out-in">
            <KeepAlive>
                <component :is="activeComponent.component" />
            </KeepAlive>
        </Transition>

    </GuestLayout>
</template>

<script setup>
    import {shallowRef} from 'vue';
    import GuestLayout from '@/Layouts/Guest.vue';
    import PlatformLoginCard from '@/Pages/Auth/Partials/PlatformLoginCard.vue';
    import OneTimePasswordLoginCard from '@/Pages/Auth/Partials/OneTimePasswordLoginCard.vue';

    const activeComponent = shallowRef({
        title: 'PlatformLoginCard',
        component: PlatformLoginCard
    });

    const setActiveComponent = (title, component) => {
        activeComponent.value = {
            title: title,
            component: component
        };
    };
</script>

<style lang="scss" scoped>
    [data-comp=LoginGoogle] {
        .link {
            @apply underline text-sm text-gray-600 hover:text-gray-900 transition ease-in-out duration-200;
        }

    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.2s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>
